<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
  export default {
      provide () {
          return {
              reload: this.reload
          }
      },
      data () {
          return {
              isRouterAlive: true
          }
      },
      created() {
          // 解决安卓手机调出来软键盘，屏幕高度被挤压的问题
          // 获取当前可视区域的高度
          const height = document.documentElement.clientHeight;
          // 在页面整体加载完毕时
          window.onload = function () {
              // 把获取到的高度赋值给根div
              document.getElementById('app').style.height = `${height}px`;
          }
      },
      methods:{
          reload () {
              console.log(888);
              this.isRouterAlive = false
              this.$nextTick(function () {
                  this.isRouterAlive = true
              })
          }
      }
  }
</script>
<style lang="less">
#app {
  font-family: PingFangSC-Medium, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
