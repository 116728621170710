import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import '@/assets/style/base.less'
import { Toast, ActionSheet, Pagination, Overlay, List, Tab, Tabs } from 'vant'
import VueClipboard from 'vue-clipboard2'
import VConsole from 'vconsole';
Vue.use(Toast)
Vue.use(ActionSheet)
Vue.use(Pagination)
Vue.use(Overlay)
Vue.use(List)
Vue.use(Tab);
Vue.use(Tabs);

Vue.use(VueClipboard)
Vue.config.productionTip = false
// 拦截器
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'yidianlife'
  next()
})

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')


// new VConsole()  // todo