const unionActRouter = [

  // 普卡  ordinary
  // 钻石卡 masonry

  // 新人  newPeople
  // 复购  Repurchase

  // 普卡-新人
  {
    path: '/unionAct/ordinaryNewPeople',
    name: 'unionActOrdinaryNewPeople',
    meta: { title: '首页' },
    component: () => import('@/views/unionAct/index.vue')
  },
  // 普卡-复购
  {
    path: '/unionAct/ordinaryRepurchase',
    name: 'unionActOrdinaryRepurchase',
    meta: { title: '首页' },
    component: () => import('@/views/unionAct/index.vue')
  },
  // 钻石卡-新人
  {
    path: '/unionAct/masonryNewPeople',
    name: 'unionActMasonryNewPeople',
    meta: { title: '首页' },
    component: () => import('@/views/unionAct/index.vue')
  },
  // 钻石卡-复购
  {
    path: '/unionAct/masonryRepurchase',
    name: 'unionActMasonryRepurchase',
    meta: { title: '首页' },
    component: () => import('@/views/unionAct/index.vue')
  },
  {
    path: '/unionAct/record',
    name: 'unionActRecord',
    meta: { title: '领取记录' },
    component: () => import('@/views/unionAct/record.vue')
  },
]
export default unionActRouter
