const shantouRouter = [
  {
    path: '/shantou/login',
    name: 'ShantouLogin',
    meta: { title: '登录' },
    component: () => import('@/views/shantou-project/Login.vue')
  },
  {
    path: '/shantou/releasepage',
    name: 'ShantouReleasePage',
    meta: { title: '下发页' },
    component: () => import('@/views/shantou-project/ReleasePage.vue')
  },
  {
    path: '/shantou/releaserecord',
    name: 'ShantouReleaseRecord',
    meta: { title: '下发记录' },
    component: () => import('@/views/shantou-project/ReleaseRecord.vue')
  }
]
export default shantouRouter
