const guangfaRouter = [
  {
    path: '/guangfa/login',
    name: 'GuanfaLogin',
    meta: { title: '兑换' },
    component: () => import('@/views/guangfa-project/Login.vue')
  },
  {
    path: '/guangfa/home',
    name: 'GuanfaExchangeHome',
    meta: { title: '兑换' },
    component: () => import('@/views/guangfa-project/ExchangeHome.vue')
  },
  {
    path: '/guangfa/page',
    name: 'ExchangePage',
    meta: { title: '兑换' },
    component: () => import('@/views/guangfa-project/ExchangePage.vue')
  }
]
export default guangfaRouter
